// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-advanced-js": () => import("./../src/templates/advanced.js" /* webpackChunkName: "component---src-templates-advanced-js" */),
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-home-js": () => import("./../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-product-js": () => import("./../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-store-js": () => import("./../src/templates/store.js" /* webpackChunkName: "component---src-templates-store-js" */)
}

